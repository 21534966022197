import React, { createContext, Dispatch, useContext, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import { GiftCardDesign } from 'types/gift-card.type';

export interface GiftCardState {
  designs: GiftCardDesign[];
  currentDesign?: GiftCardDesign;
  selectedAmount?: number;
}

const initialState: GiftCardState = {
  designs: [],
  selectedAmount: 200,
};

type Action =
  | { type: 'SET_DESIGNS'; designs: GiftCardDesign[] }
  | { type: 'SET_CURRENT_DESIGN'; design: GiftCardDesign }
  | { type: 'SET_SELECTED_AMOUNT'; amount: number }
  | { type: 'RESET' };

function giftCardReducer(draft: GiftCardState, action: Action) {
  switch (action.type) {
    case 'SET_DESIGNS':
      draft.designs = action.designs || [];
      return draft;
    case 'SET_CURRENT_DESIGN':
      draft.currentDesign = action.design;
      return draft;
    case 'SET_SELECTED_AMOUNT':
      draft.selectedAmount = action.amount;
      return draft;
    case 'RESET':
      return initialState;
  }
}

const GiftCardStateContext = createContext<GiftCardState>(initialState);

const GiftCardDispatchContext = createContext<Dispatch<Action> | undefined>(
  undefined,
);

export const GiftCardProvider = (props: {
  value?: typeof initialState;
  children: React.ReactNode;
}) => {
  const { value, children } = props;

  const [state, dispatch] = useImmerReducer(
    giftCardReducer,
    Object.assign({}, initialState, value, {
      currentDesign:
        value?.currentDesign ||
        (!!value?.designs?.length ? value.designs[0] : null),
    }),
  );

  useEffect(() => {
    dispatch({
      type: 'SET_DESIGNS',
      designs: value?.designs,
    });

    dispatch({
      type: 'SET_CURRENT_DESIGN',
      design:
        value?.currentDesign ||
        (!!value?.designs?.length ? value.designs[0] : null),
    });
  }, [value]);

  const giftCardState = Object.assign({}, state);

  return (
    <GiftCardStateContext.Provider value={giftCardState}>
      <GiftCardDispatchContext.Provider value={dispatch}>
        {children}
      </GiftCardDispatchContext.Provider>
    </GiftCardStateContext.Provider>
  );
};

export function useGiftCardState(): GiftCardState {
  const context = useContext(GiftCardStateContext);

  if (context === undefined) {
    throw new Error(`useGiftCard must be used inside an GiftCardProvider`);
  }

  return context;
}

export function useGiftCardDispatch() {
  const context = useContext(GiftCardDispatchContext);

  if (context === undefined) {
    throw new Error(`useGiftCard must be used inside an GiftCardProvider`);
  }

  return context;
}

export const useGiftCard = (): [GiftCardState, Dispatch<Action>] => [
  useGiftCardState(),
  useGiftCardDispatch(),
];
