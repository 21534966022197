export const COLORS_MAP = {
  canyon: 'red',
  coral: 'red',
  sedona: 'red',
  mint: 'green',
  forest: 'green',
  meadow: 'green',
  sky: 'blue',
  midnight: 'blue',
  stone: 'grey',
  fog: 'grey',
  snow: 'cream',
};
