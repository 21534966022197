import React, { createContext, Dispatch, useContext, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import {
  filterProducts,
  findSku,
  getRecommendedProducts,
} from 'modules/shop/shop/sections/product.utils';
import { Photo, SKU } from 'types/product.type';
import { ReviewsData } from 'types/review.type';

export interface ProductFilters {
  category?: string;
  inStock?: boolean;
  colors?: string[];
  types?: string[];
  rooms?: string[];
  sort?: string;
}

export interface ProductState {
  records: SKU[];
  filters?: ProductFilters;
  filteredRecords?: SKU[];
  activeSku?: string;
  currentProduct?: SKU;
  recommendedProducts?: SKU[];
  allCurrentProductPhotos?: Photo[];
  reviewsData?: ReviewsData;
  currentProductInStock?: boolean;
}

const initialState: ProductState = {
  records: [],
  filters: {},
};

type Action =
  | { type: 'SET_RECORDS'; records: SKU[] }
  | { type: 'SET_FILTERS'; filters: ProductFilters }
  | { type: 'SET_CURRENT_SKU'; sku: string }
  | { type: 'SET_REVIEWS'; data: ReviewsData }
  | { type: 'RESET' };

function productReducer(draft: ProductState, action: Action) {
  switch (action.type) {
    case 'SET_RECORDS':
      draft.records = action.records;
      return draft;
    case 'SET_FILTERS':
      draft.filters = action.filters;
      return draft;
    case 'SET_CURRENT_SKU':
      draft.activeSku = action.sku;
      return draft;
    case 'SET_REVIEWS':
      draft.reviewsData = action.data;
      return draft;
    case 'RESET':
      return initialState;
  }
}

const ProductStateContext = createContext<ProductState>(initialState);

const ProductDispatchContext = createContext<Dispatch<Action> | undefined>(
  undefined,
);

export const ProductProvider = (props: {
  value?: typeof initialState;
  children: React.ReactNode;
}) => {
  const { value, children } = props;

  const [state, dispatch] = useImmerReducer(
    productReducer,
    Object.assign({}, initialState, value),
  );

  useEffect(() => {
    if (value?.activeSku) {
      dispatch({ type: 'SET_CURRENT_SKU', sku: value.activeSku });
    }
  }, [value?.activeSku]);

  useEffect(() => {
    dispatch({ type: 'SET_RECORDS', records: value?.records ?? [] });
  }, [value?.records]);

  let calculatedState;

  if (state.activeSku) {
    const currentProduct = findSku(state.records, state.activeSku);

    calculatedState = {
      currentProduct,
      currentProductInStock: currentProduct?.stocks > 0,
      recommendedProducts: getRecommendedProducts(
        state.records,
        state.activeSku,
      ),
      allCurrentProductPhotos: [
        ...(currentProduct.photoUrls.photos.lifestyle ?? []),
        ...(currentProduct.photoUrls.photos.product ?? []),
        ...(currentProduct.photoUrls.photos.lifestyleset ?? []),
      ],
    };
  }

  const productState = Object.assign(
    {},
    state,
    {
      filteredRecords: filterProducts(state.records, state.filters),
    },
    calculatedState,
  );

  return (
    <ProductStateContext.Provider value={productState}>
      <ProductDispatchContext.Provider value={dispatch}>
        {children}
      </ProductDispatchContext.Provider>
    </ProductStateContext.Provider>
  );
};

export function useProductState(): ProductState {
  const context = useContext(ProductStateContext);

  if (context === undefined) {
    throw new Error(`useProduct must be used inside a ProductProvider`);
  }

  return context;
}

export function useProductDispatch() {
  const context = useContext(ProductDispatchContext);

  if (context === undefined) {
    throw new Error(`useProduct must be used inside a ProductProvider`);
  }

  return context;
}

export const useProduct = (): [ProductState, Dispatch<Action>] => [
  useProductState(),
  useProductDispatch(),
];
