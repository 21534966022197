import React, { ErrorInfo } from 'react';
import NextApp from 'next/app';
import { AppRenderProps } from 'types/app-render';
import { isBrowser } from '@unly/utils';
import get from 'lodash/get';
import i18nextInit from 'utils/i18nextInit';
import { LayoutProps } from 'types/layout-props';
import { theme } from 'styles/theme';
import { ThemeProvider } from 'styled-components/macro';
import { GlobalStyle } from 'styles/base';
import { UserAgentProvider } from 'layout/user-agent';
import seo from 'config/seo';
import { DefaultSeo } from 'next-seo';
import { AuthProvider } from 'context/auth.context';
import { CartProvider } from 'context/cart.context';
import { ModalProvider } from 'context/modal.context';
import { CookiesProvider } from 'react-cookie';
import { NotificationsProvider } from 'context/notification.context';
import { LayoutProvider } from 'context/layout.context';
import { initLogRocket } from 'utils/log-rocket';
import { ShopLayout } from 'layout/layout';
import { ProductProvider } from 'context/product.context';
import { GiftCardProvider } from 'context/gift-card.context';
import { SearchProvider } from 'context/search.context';
import { createLogger } from '@unly/utils-simple-logger';

const logger = createLogger({
  label: 'post',
});

i18nextInit();
initLogRocket();

class PorterApp extends NextApp {
  render(): JSX.Element {
    const { Component, pageProps, router, err }: AppRenderProps = this.props;

    const layoutProps: LayoutProps = {
      ...pageProps,
      err,
      router,
    };

    const Layout = Component['Layout'] || ShopLayout;

    // todo: remove UserAgentProvider
    return (
      <ThemeProvider theme={theme}>
        <AuthProvider value={pageProps.authState}>
          <CookiesProvider>
            <LayoutProvider value={pageProps.layoutState}>
              <CartProvider value={pageProps.cartState}>
                <ProductProvider value={pageProps.productState}>
                  <NotificationsProvider>
                    <UserAgentProvider value={{} as any}>
                      <GiftCardProvider value={pageProps.giftCardState}>
                        <SearchProvider>
                          <GlobalStyle />
                          <ModalProvider>
                            <Layout {...layoutProps}>
                              <DefaultSeo {...seo} />
                              <Component {...pageProps} />
                            </Layout>
                          </ModalProvider>
                        </SearchProvider>
                      </GiftCardProvider>
                    </UserAgentProvider>
                  </NotificationsProvider>
                </ProductProvider>
              </CartProvider>
            </LayoutProvider>
          </CookiesProvider>
        </AuthProvider>
      </ThemeProvider>
    );
  }
}

export default PorterApp;
