import { DefaultSeoProps } from 'next-seo';
import { environment } from 'config/environment';
import GantriLogo from 'public/logo-large.png';

export default {
  title: 'Gantri ® | Designer lights. Sustainably made.',
  description:
    'Discover and shop a new generation of lights that are exclusively created by leading global designers and sustainably made at the Gantri Factory using proprietary 3D printing.',
  canonical: environment.HOST_URL,
  openGraph: {
    type: 'website',
    locale: 'en',
    site_name: 'Gantri',
    defaultImageWidth: 1200,
    defaultImageHeight: 1200,
    images: [
      {
        url: GantriLogo,
        alt: 'Gantri logo',
      },
    ],
  },
  facebook: {
    appId: environment.FACEBOOK_APP_ID,
  },
  twitter: {
    handle: '@gantri',
    site: '@gantri',
    cardType: 'summary_large_image',
  },
  additionalMetaTags: [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, maximum-scale=1',
    },
  ],
} as DefaultSeoProps;
