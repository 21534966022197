import i18next, { i18n } from 'i18next';
import map from 'lodash/map';
import { initReactI18next } from 'react-i18next';
import { DEFAULT_LANG } from './i18n';
import commonLocale from '../locales/en/common.json';
import accountLocale from '../locales/en/account.json';
import affiliatesLocale from '../locales/en/affiliates.json';
import ammunitionLocale from '../locales/en/ammunition.json';
import blogLocale from '../locales/en/blog.json';
import cartLocale from '../locales/en/cart.json';
import cartSummaryLocale from '../locales/en/cartSummary.json';
import aboutLocale from '../locales/en/about.json';
import acceptReferralLocale from '../locales/en/acceptReferral.json';
import checkoutLocale from '../locales/en/checkout.json';
import designersLocale from '../locales/en/designers.json';
import factoryLocale from '../locales/en/factory.json';
import favoriteModalLocale from '../locales/en/favoriteModal.json';
import feedbackModalLocale from '../locales/en/feedbackModal.json';
import homeLocale from '../locales/en/home.json';
import materialsLocale from '../locales/en/materials.json';
import pressLocale from '../locales/en/press.json';
import productLocale from '../locales/en/product.json';
import quizLocale from '../locales/en/quiz.json';
import quizModalLocale from '../locales/en/quizModal.json';
import quizPicksLocale from '../locales/en/quizPicks.json';
import referLocale from '../locales/en/refer.json';
import registerLocale from '../locales/en/register.json';
import resetPasswordLocale from '../locales/en/resetPassword.json';
import shopLocale from '../locales/en/shop.json';
import shopAllLocale from '../locales/en/shopAll.json';
import signInLocale from '../locales/en/signIn.json';
import storefrontLocale from '../locales/en/storefront.json';
import giftCardsLocale from '../locales/en/gift-cards.json';
import redeemGiftCardLocale from '../locales/en/redeem-gift-card.json';
import reachLocale from '../locales/en/reach.json';
import sprkLocale from '../locales/en/sprk.json';

const defaultLocales = {
  en: {
    about: aboutLocale,
    acceptReferral: acceptReferralLocale,
    account: accountLocale,
    affiliates: affiliatesLocale,
    ammunition: ammunitionLocale,
    blog: blogLocale,
    cart: cartLocale,
    cartSummary: cartSummaryLocale,
    checkout: checkoutLocale,
    common: commonLocale,
    designers: designersLocale,
    factory: factoryLocale,
    favoriteModal: favoriteModalLocale,
    feedbackModal: feedbackModalLocale,
    giftCards: giftCardsLocale,
    home: homeLocale,
    materials: materialsLocale,
    press: pressLocale,
    product: productLocale,
    quiz: quizLocale,
    quizModal: quizModalLocale,
    quizPicks: quizPicksLocale,
    reach: reachLocale,
    redeemGiftCard: redeemGiftCardLocale,
    refer: referLocale,
    register: registerLocale,
    resetPassword: resetPasswordLocale,
    shop: shopLocale,
    shopAll: shopAllLocale,
    signIn: signInLocale,
    sprk: sprkLocale,
    storefront: storefrontLocale,
  },
};

export declare type I18nextResourceLocale = {
  [i18nKey: string]: string | I18nextResourceLocale; // The value can either be a string, or a nested object, itself containing either a string, or a nest object, etc.
};

/**
 * One or more i18next resources, indexed by lang
 *
 * @example
 * {
 *   es: {
 *     "login": {
 *       "label": "Log in",
 *       "user": "User Name"
 *     }
 *   }
 * }
 */
export declare type I18nextResources = {
  [lang: string]: I18nextResourceLocale;
};

/**
 * @type {string}
 */
const defaultNamespace = 'common';

const i18nextInit = (): i18n => {
  const plugins = [initReactI18next];

  const i18nInstance = i18next;
  map(plugins, plugin => i18nInstance.use(plugin));
  // @ts-ignore
  i18nInstance.init({
    // See https://www.i18next.com/overview/configuration-options
    resources: defaultLocales,
    cleanCode: true,
    debug: false,
    saveMissing: process.env.APP_STAGE === 'development',
    saveMissingTo: defaultNamespace,
    lng: DEFAULT_LANG,
    fallbackLng: DEFAULT_LANG,
    ns: [defaultNamespace],
    defaultNS: defaultNamespace,
    interpolation: {
      escapeValue: false,
    },
    react: {
      bindI18n: 'languageChanged editorSaved',
      useSuspense: false,
    },
    load: 'languageOnly',
  });

  return i18nInstance;
};

export default i18nextInit;
