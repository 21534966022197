import { Photo, SKU } from 'types/product.type';
import { COLORS_MAP } from 'modules/shop/shop/shop.constants';
import orderBy from 'lodash/orderBy';

export const filterProducts = (records: SKU[], filters) => {
  if (filters) {
    let filtered = (records || []).filter((record: SKU) => {
      if (record.category.toLowerCase() !== `${filters.category} light`) {
        return false;
      }

      if (filters.inStock && !record.stocks) {
        return false;
      }

      if (
        filters.rooms &&
        filters.rooms.length &&
        !record.rooms.some((room: string) => filters.rooms.includes(room))
      ) {
        return false;
      }

      if (
        filters.colors &&
        filters.colors.length &&
        !filters.colors.includes(
          COLORS_MAP[record.color.code] ?? record.color.code,
        )
      ) {
        return false;
      }

      if (
        filters.types &&
        filters.types.length &&
        !filters.types.includes(record.subCategory.toLowerCase())
      ) {
        return false;
      }

      return true;
    });

    if (filters.sort) {
      return orderBy(filtered, ['price'], [filters.sort]);
    }

    return filtered;
  }

  return records;
};

export const filterRecommendedSkus = (records: SKU[], sku: string) => {
  const currentSku = records?.find((product: SKU) => product.sku === sku);

  const recommendedProductIds = currentSku?.recommendedProductsSkus?.map(
    (sku: string) => +sku.split('-')[0],
  );

  return (
    records?.filter(
      (product: SKU) =>
        +product.id === +currentSku.id ||
        recommendedProductIds?.includes(+product.id),
    ) || []
  );
};

export const findSku = (records: SKU[], sku: string) => {
  const found = records.find(record => record.sku === sku);

  if (found) {
    if (!found.photoUrls.photos.product) {
      found.photoUrls.photos.product = [];
    }

    if (!found.photoUrls.photos.lifestyleset) {
      found.photoUrls.photos.lifestyleset = [];
    }

    if (!found.photoUrls.photos.lifestyle) {
      found.photoUrls.photos.lifestyle = [];
    }

    return found;
  }

  return null;
};

export const getSkuData = (records: SKU[], productId: number) => {
  return records
    .filter(record => record.id === productId)
    .reduce(
      (accumulator, current) =>
        Object.assign({}, accumulator, {
          [current.sku]: current,
        }),
      {},
    );
};

export const getRecommendedProducts = (records: SKU[], sku: string) => {
  const currentProduct = findSku(records, sku);

  return (
    currentProduct?.recommendedProductsSkus?.map(sku =>
      Object.assign(
        {},
        records.find(record => record.sku === sku),
        {
          skusData: getSkuData(records, +sku.split('-')[0]),
        },
      ),
    ) || []
  );
};

export const extractLifestylePhotos = (records: SKU[], id: number): Photo[] => {
  let photos = [];

  for (let record of records || []) {
    if (record.id === id && record.photoUrls.photos?.lifestyle?.length) {
      photos = photos.concat(record.photoUrls.photos?.lifestyle);
    }
  }

  return photos;
};
